import React from "react"
import Breadcrumb from "./breadcrumb"

const WhereToBuy = () => {
  return (
    <section className="where-to-buy-component is-margin-vertical-8">
      <div className="is-margin-vertical-10">
        <div className="is-padding-horizontal-4-touch has-text-centered">
          <div>
            <img src="/images/icons/where-store.svg" alt="Icone loja" />
          </div>

          <div className="has-text-centered">
            <Breadcrumb theme="dark" center={true} items={[
              { label: "Home", link: "/" },
              { label: "Onde Comprar", link: "#" },
            ]} />
          </div>

          <div className="is-margin-bottom-8">
            <h1 className="is-size-1 has-text-dark has-text-weight-bold">
              Localize uma loja mais próxima
            </h1>
          </div>

          <div>
            <p className="is-font-size-20 has-text-grey">
              Encontre aqui os produtos Superbom onde você estiver.
            </p>
          </div>
        </div>
      </div>

      <div>
        <iframe
          title="Mapa"
          id="gofind-iframe"
          frameBorder="0"
          src="https://superbom.pertinhodemim.com"
          width="100%"
          style={{ minHeight: 700, border: "1px solid #f2f2f2" }}
          allow="geolocation"></iframe>
      </div>
    </section>
  )
}

export default WhereToBuy
