import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const HomeContact = () => {
  return (
    <section className="home-contact-component is-margin-bottom-12 is-margin-top-15">
      <div className="container is-padding-horizontal-4-touch">
        <div className="columns is-centered is-gapless">
          <div className="column is-6">
            <div className="is-margin-bottom-9">
              <div className="has-text-centered has-text-left-mobile" style={{ maxWidth: 500, margin: '0 auto' }}>
                <div className="is-margin-bottom-8">
                  <h2 className="title is-1">Entre em Contato</h2>
                </div>
                <p className="is-5">
                  Entre em contato com a gente para saber mais sobre nossos produtos, para trabalhar em nossa empresa e para se tornar um de nossos revendedores.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="is-margin-bottom-6 is-padding-horizontal-10 is-padding-horizontal-0-touch">
          <div className="columns">
            <div className="column">
              <ContactItem
                id="quero-vender"
                link="/quero-vender/"
                src="/images/icons/contact-businnes.svg"
                title="Tenha a Superbom na sua loja"
                description="Nossos produtos têm a cara da sua empresa? Então, você pode levar a Superbom para a sua loja." />
            </div>
            <div className="column">
              <ContactItem
                id="trabalhe-conosco"
                link="https://jobs.solides.com/superbom"
                src="/images/icons/contact-job.svg"
                title="Trabalhe conosco"
                target="_blank" 
                rel="noopener noreferrer"
                description="Sonha em fazer parte do nosso quadro de colaboradores? Cadastre-se em nosso banco de talentos." />
            </div>
            <div className="column">
              <ContactItem
                id="fale-conosco"
                link="/fale-conosco/"
                src="/images/icons/contact-sac.svg"
                title="SAC"
                description="Entre em contato com a gente para tirar suas dúvidas e para saber mais sobre nossos produtos." />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const ContactItem = ({ id, link, src, title, description, target, rel }) => {
  return (
    <div className="contact-item">
      <div className="contact-item-icon is-margin-bottom-4">
        <LazyLoadImage src={src} alt={title} style={{ height: 65 }} />
      </div>
      <div className="is-margin-bottom-2">
        <h3 className="is-font-size-20 is-line-height-24 has-text-weight-extrabold" style={{ width: 200, height: 60, margin: '0 auto' }}>
          {title}
        </h3>
      </div>
      <p className="is-font-size-16 is-line-height-24 is-margin-bottom-8 has-text-grey">
        {description}
      </p>
      <a href={link} id={id} target={target} rel={rel} className="button is-primary is-rounded">
        <strong>Saiba Mais</strong>
      </a>
    </div>
  )
}

export default HomeContact
