import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WhereToBuy from "../components/where-to-buy"
import HomeContact from "../components/home-contact"

const Page = ({ data }) => {
  const post = data.wordpressMainPage

  return (
    <Layout>
      <SEO post={post} />
      <WhereToBuy />
      <HomeContact />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordpressMainPage(name: {eq: "onde-comprar"}) {
      ...MainPageFull
    }
  }
`
